import Box from '@mui/material/Box'
import Typography from '@app/stories/Typography'
import { collection } from '@app/firebase/firestore'
import {
  CircularProgress,
  Tooltip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useCurrentProfile } from '@app/context/CurrentUser'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import useQuery from '@app/hooks/useQuery'
import { benefits } from '@app/libs/planConstants'

const SubTooltip = ({ subtext }) => {
  return (
    <Tooltip title={subtext} arrow>
      <IconButton size='small' sx={{ ml: 1 }}>
        <InfoOutlinedIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  )
}

const getPlanData = async (planID) => {
  const planRef = await collection('networks').doc(planID).get()

  if (!planRef.exists) {
    throw new Error('Plan not found')
  }

  const planData = planRef.data()

  if (!planData.planDesign) {
    throw new Error('Plan design not found')
  }

  return {
    ...planData,
  }
}

const PlanDesign = () => {
  const { managedPlanData } = useCurrentProfile()

  const {
    data: planData,
    loading,
    error,
  } = useQuery(() => getPlanData(managedPlanData?.managedPlanID), [managedPlanData?.managedPlanID])

  if (loading) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' minHeight='200px'>
        <CircularProgress size={100} />
      </Box>
    )
  }

  if (error) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' minHeight='200px'>
        {error}
      </Box>
    )
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ width: '100%', mb: 4, mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center' sx={{ bgcolor: '#652d92', fontWeight: 'bold' }} colSpan={2}>
                <Typography variant='h2' color='white' textAlign='center'>
                  {planData?.planType || ''} Plan Benefits
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ bgcolor: '#f1e7fb', fontWeight: 'bold' }}>Benefits</TableCell>
              <TableCell sx={{ bgcolor: '#f1e7fb', fontWeight: 'bold', textAlign: 'center' }}>
                In-Network
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {benefits(planData).map((benefit) => (
              <TableRow key={benefit.label}>
                <TableCell>
                  <Typography variant='body1'>
                    <b>{benefit.label}</b>
                    <SubTooltip subtext={benefit.description} />
                  </Typography>
                </TableCell>
                <TableCell align='center'>{benefit.value || '--'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper} sx={{ width: '100%', mb: 4, mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ bgcolor: '#652d92', fontWeight: 'bold' }} colSpan={4} align='center'>
                <Typography variant='h2' color='white' align='center'>
                  Prescription Drug Copayments
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align='center'
                sx={{ whiteSpace: 'nowrap', bgcolor: '#f1e7fb', fontWeight: 'bold' }}
              >
                Coverage
              </TableCell>
              <TableCell align='center' colSpan={2} sx={{ bgcolor: '#f1e7fb', fontWeight: 'bold' }}>
                Member Responsibility
              </TableCell>
              <TableCell align='center' sx={{ bgcolor: '#f1e7fb', fontWeight: 'bold' }}>
                Precertification Requirements and Other Coverage Notes
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {planData?.planDesign?.perscriptionDrugs?.tiers?.map((tier, index) => (
              <TableRow key={index}>
                {index === 0 && (
                  <TableCell
                    rowSpan={planData?.planDesign?.perscriptionDrugs?.tiers?.length || 1}
                    align='center'
                    sx={{ width: '20%' }}
                  >
                    <Typography variant='body1'>
                      {planData?.planDesign?.perscriptionDrugs?.coverageMessage ||
                        'No Coverage Message'}
                    </Typography>
                  </TableCell>
                )}
                <TableCell sx={{ whiteSpace: 'nowrap' }} align='center'>
                  <Typography sx={{ whiteSpace: 'nowrap' }} variant='body1'>
                    {tier.name || '---'}
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography variant='body1'>{tier.message || '---'}</Typography>
                </TableCell>
                {index === 0 && (
                  <TableCell
                    sx={{ width: '20%' }}
                    rowSpan={planData?.planDesign?.perscriptionDrugs?.tiers?.length || 1}
                    align='center'
                  >
                    <Typography variant='body1'>
                      {planData?.planDesign?.perscriptionDrugs?.requirementsMessage ||
                        'No Requirements'}
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default PlanDesign

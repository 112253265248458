import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Divider, Hidden } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import Button from '../stories/Button'
// import { useOnScreen } from '@app/context/OnScreen'
import Container from '../stories/Container'
import Section from '../stories/Section'
import Typography from '../stories/Typography'
import { scrollToHash } from '../libs/scroll'
import FormattedText from '../stories/FormatFromDB'
import YoutubeEmbed from './Embeds/YoutubeEmbed'

import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'

const NetworkHero = ({ landerData, networkSlug }) => {
  const navigate = useNavigate()
  const [heroImageLink, setHeroImageLink] = useState(null)

  const {
    heroTitle,
    heroSubText,
    heroImage,
    heroBackgroundColor,
    ctaButton,
    ctaText,
    featuredServices,
    displayFeaturedServices,
    hideInfoBoxes,
    hideYouShouldKnow,
    videoEmbedID,
  } = landerData

  const { planColorPrimary } = useWhiteLabelingContext()

  useEffect(() => {
    if (Array.isArray(heroImage)) {
      setHeroImageLink(heroImage[0]?.downloadURL)
    } else {
      setHeroImageLink(null)
    }
  }, [heroImage])

  // Quick links is an object
  const quickLinkPresent = landerData?.quickLinks && Object.keys(landerData.quickLinks).length !== 0

  return (
    <Section
      variant='noPadding'
      style={{
        backgroundColor: `${heroBackgroundColor || '#fff'}`,
        paddingTop: quickLinkPresent ? '100px' : '64px',
        paddingBottom: '36px',
      }}
    >
      <Hidden mdDown>
        <Container>
          <Grid container display='flex' alignItems='center'>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box maxWidth={500}>
                {heroTitle && (
                  <Typography
                    variant='h1'
                    gutterBottom
                    weight='medium'
                    style={{
                      fontFamily: 'Poppins',
                    }}
                  >
                    {heroTitle}
                  </Typography>
                )}
                {heroSubText && (
                  <FormattedText
                    text={heroSubText}
                    textType='body1'
                    weight='light'
                    style={{
                      fontFamily: 'Poppins',
                    }}
                  />
                )}
                {ctaButton && (
                  <Box mt={3}>
                    <Button
                      display='standard'
                      onClick={() => {
                        scrollToHash('#specialties')
                      }}
                      size='large'
                      style={{
                        marginRight: '1rem',
                        backgroundColor: planColorPrimary,
                        '&:hover': {
                          backgroundColor: `${planColorPrimary}` || '#652d92', // Adjust the hover background color
                        },
                        '&.Mui-focused': {
                          backgroundColor: `${planColorPrimary}` || '#652d92', // Adjust the onClick background color
                        },
                      }}
                    >
                      {ctaText}
                    </Button>
                  </Box>
                )}
                {displayFeaturedServices && featuredServices?.length > 0 && (
                  <Box mt={3}>
                    {featuredServices.map((service) => (
                      <Box mr={1} mt={1} display='inline-block' key={service.serviceName}>
                        <Button
                          variant='outlined'
                          onClick={() => {
                            navigate(`/plans/${networkSlug}/service/${service.misheCode}`)
                          }}
                          size='small'
                          mr={1}
                        >
                          {service.serviceName}
                        </Button>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </Grid>
            {(heroImageLink || videoEmbedID) && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                {videoEmbedID ? (
                  <YoutubeEmbed embedID={videoEmbedID} width='100%' />
                ) : (
                  <img src={heroImageLink} alt='Network Hero' width='100%' />
                )}
              </Grid>
            )}
            {!hideInfoBoxes && (
              <>
                <Box mt={3} mb={1} width='100%'>
                  <Divider />
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Box width='100%' padding='20px'>
                      <Typography variant='h5'>No insurance needed</Typography>
                      We contract directly with our clinician partners so you can access quality
                      care without hassles or surprises.
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Box width='100%' padding='20px'>
                      <Typography variant='h5'>Concierge support</Typography>
                      Our case managers are just a call or text away to help coordinate appointment
                      scheduling and your treatment plan.
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Box width='100%' padding='20px'>
                      <Typography variant='h5'>US-licensed clinicians</Typography>
                      We partner with accredited clinicians and board-certified specialists.
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Box width='100%' padding='20px'>
                      <Typography variant='h5'>Your health data is secure</Typography>
                      We partner with leading technology companies to keep your information secure
                      and protected by law.
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
            {!hideYouShouldKnow && (
              <>
                <Box mt={1} mb={1} width='100%'>
                  <Divider />
                </Box>
                <Box mt={3} width='100%'>
                  <Grid
                    container
                    alignItems='center'
                    width='100%'
                    style={{
                      boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                      borderRadius: '5px',
                      backgroundColor: landerData.yskBgColor || '#f5f5f5',
                      padding: '15px',
                    }}
                  >
                    <Typography variant='h5' color='textPrimary'>
                      You should know
                    </Typography>
                    <Box width='100%' alignItems='center' display='flex'>
                      <Typography variant='body2' weight='light'>
                        Based on your health benefits, your service may be reimbursable by your
                        insurance plan. Check with your case manager to determine eligibility after
                        you receive your service. If eligible, your case manager will provide you
                        with the appropriate documentation needed. Mishe does not accept insurance
                        at this time, and is not intended for beneficiaries of government payor
                        programs, including Medicare and Medicaid.
                      </Typography>
                    </Box>
                  </Grid>
                </Box>
              </>
            )}
          </Grid>
        </Container>
      </Hidden>
      <Hidden mdUp>
        <Section variant='landing'>
          <Container>
            <Grid container spacing={3} display='flex' alignItems='center'>
              {(heroImageLink || videoEmbedID) && (
                <Grid item xs={12} sm={12}>
                  {videoEmbedID ? (
                    <YoutubeEmbed embedID={videoEmbedID} width='100%' />
                  ) : (
                    <img src={heroImageLink} alt='Network Hero' width='100%' />
                  )}
                </Grid>
              )}
              <Grid item xs={12} sm={12}>
                {heroTitle && (
                  <Typography
                    variant='h1'
                    gutterBottom
                    align='center'
                    weight='medium'
                    style={{
                      fontFamily: 'Poppins',
                    }}
                  >
                    {heroTitle}
                  </Typography>
                )}
                {heroSubText && (
                  <FormattedText
                    text={heroSubText}
                    textType='body1'
                    weight='light'
                    align='center'
                    style={{
                      fontFamily: 'Poppins',
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                {ctaButton && (
                  <Box display='flex' justifyContent='center'>
                    <Button
                      display='standard'
                      onClick={() => {
                        scrollToHash('#specialties')
                      }}
                      size='large'
                      style={{
                        backgroundColor: planColorPrimary,
                        '&:hover': {
                          backgroundColor: `${planColorPrimary}` || '#652d92', // Adjust the hover background color
                        },
                        '&.Mui-focused': {
                          backgroundColor: `${planColorPrimary}` || '#652d92', // Adjust the onClick background color
                        },
                      }}
                    >
                      {ctaText}
                    </Button>
                  </Box>
                )}
                {featuredServices?.length > 0 && displayFeaturedServices && (
                  <Box mt={3} display='flex' justifyContent='center'>
                    <Grid container spacing={1} display='flex' justifyContent='center'>
                      {featuredServices.map((service) => (
                        <Box mr={1} mt={1} display='inline-block' key={service.serviceName}>
                          <Button
                            key={service.serviceName}
                            variant='outlined'
                            onClick={() => {
                              navigate(`/plans/${networkSlug}/service/${service.misheCode}`)
                            }}
                            size='small'
                            mr={1}
                          >
                            {service.serviceName}
                          </Button>
                        </Box>
                      ))}
                    </Grid>
                  </Box>
                )}
              </Grid>
              {!hideInfoBoxes && (
                <>
                  <Grid item xs={12} sm={12} paddingLeft='24px'>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Box width='100%' display='flex'>
                      <CheckIcon
                        fontSize='small'
                        style={{ color: '#303030', marginRight: '10px' }}
                      />
                      <Typography variant='body2' weight='light'>
                        No insurance needed
                      </Typography>
                    </Box>
                    <Box mt={1} width='100%' display='flex'>
                      <CheckIcon
                        fontSize='small'
                        style={{ color: '#303030', marginRight: '10px' }}
                      />
                      <Typography variant='body2' weight='light'>
                        Concierge support
                      </Typography>
                    </Box>
                    <Box mt={1} width='100%' display='flex'>
                      <CheckIcon
                        fontSize='small'
                        style={{ color: '#303030', marginRight: '10px' }}
                      />
                      <Typography variant='body2' weight='light'>
                        US-licensed clinicians
                      </Typography>
                    </Box>
                    <Box mt={1} width='100%' display='flex'>
                      <CheckIcon
                        fontSize='small'
                        style={{ color: '#303030', marginRight: '10px' }}
                      />
                      <Typography variant='body2' weight='light'>
                        Your health data is secure
                      </Typography>
                    </Box>
                  </Grid>
                </>
              )}
              {!hideYouShouldKnow && (
                <>
                  <Grid item xs={12} sm={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} mt={3}>
                    <Typography variant='h5' color='textPrimary'>
                      You should know
                    </Typography>
                    <Box width='100%' alignItems='center' display='flex'>
                      <Typography variant='body2' weight='light'>
                        Based on your health benefits, your service may be reimbursable by your
                        insurance plan. Check with your case manager to determine eligibility after
                        you receive your service. If eligible, your case manager will provide you
                        with the appropriate documentation needed. Mishe does not accept insurance
                        at this time, and is not intended for beneficiaries of government payor
                        programs, including Medicare and Medicaid.
                      </Typography>
                    </Box>
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12}>
                <Divider />
              </Grid>
            </Grid>
          </Container>
        </Section>
      </Hidden>
    </Section>
  )
}

NetworkHero.propTypes = {
  landerData: PropTypes.objectOf(PropTypes.any).isRequired,
  networkSlug: PropTypes.string.isRequired,
}

export default NetworkHero

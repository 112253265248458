import { formatDateForUI } from '@app/libs/formatters'
import { formatPhoneNumberForProfile } from '@app/libs/formatters'

export const formatDollarAmount = (amount) => {
  if (amount == null || isNaN(amount)) {
    return '--'
  }
  return `$${Number(amount).toLocaleString('en-US')}`
}

export const formatBenefit = (benefit) => {
  const { copay, coinsurance, deductible } = benefit || {}

  const parts = [
    copay !== false && `${formatDollarAmount(copay)} Copay`,
    deductible && 'Deductible',
    coinsurance !== false && `${coinsurance}% CoIn`,
  ].filter(Boolean)

  return parts.join(' + ')
}

const drawElem = (ctx, text, x, y, fill = 'white', fontWeight = 'normal', fontSize = 24) => {
  ctx.font = `${fontWeight} ${fontSize}px Arial`
  ctx.fillStyle = fill
  ctx.fillText(text, x, y)
}

// once https://mishe.atlassian.net/browse/SCRUM-203 is done, we can begin cleaning up this formatter function to not rely on
// branching data structure
const planDesignFormatter = (planData) => {
  if (!planData) return
  const plan = planData.planDesign

  return {
    servNumber: planData.planContact?.phoneNumber
      ? formatPhoneNumberForProfile(planData.planContact?.phoneNumber)
      : '(601)-647-4326',
    isWaived: 'Waived',
    erCopay: plan.emergencyRoom?.copay || null,
    rxCopay: plan.prescriptionDrugs
      ? `Generic: $${plan.prescriptionDrugs.generic} / Preferred Brand: $${plan.prescriptionDrugs.preferredBrand} / Brand: $${plan.prescriptionDrugs.brand} / Specialty: $${plan.prescriptionDrugs.specialist}`
      : '$15 / $30 / $60', // remove once SCRUM-204 is resolved  https://mishe.atlassian.net/browse/SCRUM-204
    cpDrugs: '$0',
    specCP: plan.officeOrSpecialistVisit ? `$${plan.officeOrSpecialistVisit.copay} Copay` : null,
    urgentCare: plan.urgentCare
      ? `$${plan.urgentCare.copay} Copay${plan.urgentCare.deductible ? '' : ' Deduct. Waived'}`
      : null,
    inCost: plan.deductible ? `$${plan.deductible.inLower} / $${plan.deductible.inHigher}` : null,
    outCost: plan.deductible ? `$${plan.deductible.outLower} /$${plan.deductible.outHigher}` : null,
    INmax: plan.outOfPocketMax
      ? `$${plan.outOfPocketMax.inLower} /$${plan.outOfPocketMax.inHigher}`
      : null,
    OPmax: plan.outOfPocketMax
      ? `$${plan.outOfPocketMax.outLower} /$${plan.outOfPocketMax.outHigher}`
      : null,
    type: planData.planType,
    presGroup: planData.prescriptionGroup,
    effDate: planData?.effectiveDate,
    title: planData.metaTitle,
  }
}

export const planDentalDesignFormatter = (planData) => {
  if (!planData) return
  const plan = planData.planDesign
  debugger

  return {
    type: `Dental ${planData.planType} Plan`,
    effDate: planData?.effectiveDate,
    annualMax: `${plan?.dentalBenefits.annualMax}`,
    title: planData.metaTitle,
    deductible: plan?.dentalBenefits.deductible,
    dedIndv: plan?.dentalBenefits.deductible.individual,
    dedFam: plan?.dentalBenefits.deductible.family,
    dedWaved: plan?.dentalBenefits.deductible.waivedForPreventative,

    lifeOrthoMax: `${plan?.dentalBenefits.lifetimeOrthodontiaMax}`,
    preveInsur: plan?.dentalBenefits.preventiveServices.coinsurance,
    basicInsr: plan?.dentalBenefits.basicServices.coinsurance,
    majorInsr: plan?.dentalBenefits.majorServices.coinsurance,
    orthoServ: plan?.dentalBenefits.orthodontiaServices.coinsurance,
    oonBenefits: plan?.dentalBenefits.outOfNetworkBenefits,
  }
}

export const drawMCCCard = (ctx, data) => {
  const plan = planDesignFormatter(data.planData)
  const { firstName, lastName, memberId, groupId, width, height } = data

  const fontSize = width * 0.01
  let Y = height * 0.33
  let X = width * 0.21
  const incY = fontSize * 5.2

  drawElem(ctx, `${plan.title}`, X, Y, '#000')
  drawElem(ctx, `${groupId || '---'}`, X, (Y += incY))
  drawElem(ctx, `${firstName || '---'} ${lastName || '---'}`, X, (Y += incY), '#000')
  drawElem(ctx, `${memberId || '---'}`, X, (Y += incY))
  drawElem(ctx, `Rx Bin`, X, (Y += incY), '#000')
  drawElem(ctx, `PCN`, X, (Y += incY), '#000')
  drawElem(ctx, `${plan.servNumber}`, X, (Y += incY), '#000')
  drawElem(ctx, `www.affirmedrx.com`, X, (Y += incY), '#000')

  Y = height * 0.52
  X = width * 0.55
  const maxW = 420
  const lineHeight = fontSize * 2.4

  Y = wrapText(ctx, `<b>Deductibles:</b>`, X, Y, maxW, lineHeight, 'white')
  Y = wrapText(ctx, `<b>MCC Zero-Cost Network:</b> ${plan.isWaived}`, X, Y, maxW, lineHeight)
  Y = wrapText(ctx, `<b>IN:</b> ${plan.inCost} <b>OUT:</b> ${plan.outCost}`, X, Y, maxW, lineHeight)
  Y = wrapText(ctx, `<b>Max Out Of Pockets:</b>`, X, Y, maxW, lineHeight)
  Y = wrapText(ctx, `<b>IN:</b> ${plan.INmax} <b>OUT:</b> ${plan.OPmax}`, X, Y, maxW, lineHeight)
  Y = wrapText(ctx, `<b>Office Visit/Specialist:</b> ${plan.specCP}`, X, Y, maxW, lineHeight)
  Y = wrapText(ctx, `<b>Urgent Care:</b> ${plan.urgentCare}`, X, Y, maxW, lineHeight)
  Y = wrapText(ctx, `<b>RX Copay:</b> ${plan.rxCopay}`, X, Y, maxW, lineHeight)
  Y = wrapText(ctx, `<b>Cost Plus Drugs:</b> ${plan.cpDrugs}`, X, Y, maxW, lineHeight)
}

export const drawMCCDentalCard = (ctx, data) => {
  const plan = planDentalDesignFormatter(data.planData)

  const { firstName, lastName, memberId, groupId, width, height } = data

  const fontSize = width * 0.01
  let Y = height * 0.32
  let X = width * 0.19
  const incY = fontSize * 5.2

  // drawElem(ctx, `${plan.title}`, X, Y, '#000')
  drawElem(ctx, `${groupId || '---'}`, X, (Y += incY))
  drawElem(ctx, `${firstName || '---'} ${lastName || '---'}`, X, (Y += incY), '#000')
  drawElem(ctx, `${memberId || '---'}`, X, (Y += incY))

  Y = height * 0.55
  X = width * 0.55
  const maxW = 420
  const lineHeight = fontSize * 2.4
  Y = wrapText(ctx, `<b>${plan.type}:</b>`, X, Y, maxW, lineHeight, 'white')
  Y = wrapText(
    ctx,
    `<b>Dental Benefits:</b> ${plan.annualMax} Annual Max / Preventative, Basic and Major Services ${plan.lifeOrthoMax}  Lifetime Orthodontia Max
    `,
    X,
    Y,
    maxW,
    lineHeight,
  )
  Y = wrapText(
    ctx,
    `<b>Deductible</b> ${plan.dedIndv} / ${plan.dedFam} ${plan.dedWaved ? 'waived for preventative' : ''}`,
    X,
    Y,
    maxW,
    lineHeight,
  )
  Y = wrapText(ctx, `<b>Coinsurances:</b>`, X, Y, maxW, lineHeight)
  Y = wrapText(ctx, `<b>Preventative</b> ${plan.preveInsur}%`, X, Y, maxW, lineHeight)
  Y = wrapText(ctx, `<b>Basic:</b> ${plan.basicInsr}%`, X, Y, maxW, lineHeight)
  Y = wrapText(ctx, `<b>Major:</b> ${plan.majorInsr}%`, X, Y, maxW, lineHeight)
  Y = wrapText(ctx, `<b>Orthodontia:</b> ${plan.orthoServ}%`, X, Y, maxW, lineHeight)
}

export const drawDefaultCard = (ctx, data) => {
  const plan = planDesignFormatter(data.planData)
  // remove planId to use groupId,  once https://mishe.atlassian.net/browse/SCRUM-203 done
  const { firstName, lastName, memberId, groupId, planID, width, height } = data

  const fontSize = width * 0.025
  let Y = height * 0.2
  let X = width * 0.1
  const incY = fontSize * 1.2
  const incYNewLine = fontSize * 2.5

  // Left Section
  drawElem(ctx, 'Member Name', X, Y, 'white', 'bold')
  drawElem(ctx, `${firstName} ${lastName}`, X, (Y += incY))
  drawElem(ctx, 'Plan Type', X, (Y += incYNewLine), 'white', 'bold')
  drawElem(ctx, plan.type, X, (Y += incY))
  drawElem(ctx, 'Copay', X, (Y += incYNewLine), 'white', 'bold')
  drawElem(ctx, `ER Copay: ${plan.erCopay}`, X, (Y += incY))
  drawElem(ctx, `Prescription: ${plan.rxCopay}`, X, (Y += incY))
  drawElem(ctx, `Specialist Copay: $${plan.specCP}`, X, (Y += incY))

  // Center Section
  X = width / 2.5
  Y = height * 0.2
  drawElem(ctx, 'Member ID', X, Y, 'white', 'bold')
  drawElem(ctx, memberId, X, (Y += incY))
  drawElem(ctx, 'Group ID', X, (Y += incYNewLine), 'white', 'bold')
  drawElem(ctx, groupId || planID, X, (Y += incY))
  drawElem(ctx, 'Prescription Group', X, (Y += incYNewLine), 'white', 'bold')
  drawElem(ctx, plan.presGroup, X, (Y += incY))

  // bottom riight Section
  X = width / 1.5
  Y = height * 0.6

  drawElem(ctx, 'Effective Date', X, (Y += incY), 'white', 'bold')
  drawElem(ctx, formatDateForUI(plan.effDate) || '---', X, (Y += incY))
  drawElem(ctx, 'Member Service', X, (Y += incYNewLine), 'white', 'bold')
  drawElem(ctx, plan.servNumber, X, (Y += incY))
}

function wrapText(ctx, text, x, y, maxW, lineHeight, color = 'white') {
  const boldRegex = /<b>(.*?)<\/b>/g
  let words = text.split(/(<b>.*?<\/b>|\s+)/).filter((p) => p.trim() !== '')

  let currentLine = []
  let currentLineWidth = 0

  function drawCurrentLine() {
    let drawX = x
    for (let part of currentLine) {
      let isBold = part.match(boldRegex)
      let textContent = part.replace(/<\/?b>/g, '')
      let fontWeight = isBold ? 'bold' : 'normal'

      ctx.font = `${fontWeight} 22px Arial`
      let wordWidth = ctx.measureText(textContent).width

      drawElem(ctx, textContent, drawX, y, color, fontWeight, 22)
      drawX += wordWidth + 5
    }
    y += lineHeight
    currentLine = []
    currentLineWidth = 0
  }

  for (let word of words) {
    let isBold = word.match(boldRegex)
    let textContent = word.replace(/<\/?b>/g, '')
    let fontWeight = isBold ? 'bold' : 'normal'

    ctx.font = `${fontWeight} 22px Arial`
    let wordWidth = ctx.measureText(textContent).width

    if (currentLineWidth + wordWidth > maxW) {
      drawCurrentLine()
    }

    currentLine.push(word)
    currentLineWidth += wordWidth + 5
  }

  if (currentLine.length > 0) {
    drawCurrentLine()
  }

  return y
}

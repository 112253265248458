import { useState } from 'react'
import Box from '@mui/material/Box'
import InstantSearchContainerCustom from '../../containers/InstantSearchContainerCustom'
import PlanAdminProviderSearchCard from '.././PlanAdminProviderSearchCard'
import { useCurrentProfile } from '../../context/CurrentUser'

const BuildCard = ({ key, data, onClick, variant, exactMatch }) => {
  return (
    <PlanAdminProviderSearchCard
      key={key}
      data={data}
      onClick={onClick}
      variant={variant}
      exactMatch={exactMatch}
      hideButton={true}
    />
  )
}

const ProviderDirectory = () => {
  const { managedPlanData } = useCurrentProfile()
  const [searchLimit, setSearchLimit] = useState(10)

  const ProviderSearch = () => {
    const { providerSearchTagFilters = [] } = managedPlanData

    const configuration = {
      hitsPerPage: searchLimit,
    }

    if (providerSearchTagFilters?.length > 0) {
      configuration.filters = providerSearchTagFilters.map((tag) => `tags:${tag}`).join(' OR ')
    }

    return (
      <InstantSearchContainerCustom
        HitComponent={BuildCard}
        indexName='providers'
        showInitialHits
        configuration={configuration}
        searchBoxText='Search for a provider'
        noResultsMessage='No results'
        usePagination
        networkLanderSearch
        searchLimit={searchLimit}
        setSearchLimit={setSearchLimit}
        noResultsColor='#000'
      />
    )
  }

  return (
    <Box>
      <ProviderSearch />
    </Box>
  )
}

export default ProviderDirectory

import {
  Box,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import PatientMemberCards from '@app/components/PatientDashboard/PlansTab/memberCards/PatientMemberCards'

import PatientPlanCopayInfo from '@app/components/PatientPlanCopayInfo'
import PlanDocs from '@app/components/PatientDashboard/PlansTab/PlanDocs'
import RequestClaimsShareModal from './RequestClaimsShareModal'
import ShareClaimsModal from './ShareClaimsModal'
import ViewClaimsModal from './ViewClaimsModal'
import renderGauge from '../Guages'
import CertificateOfCoverage from '@app/components/PatientDashboard/CertificateOfCoverage'
import { formatCurrency, formatDateForUI } from '@app/libs/formatters'

const HEADERS = [
  'Name',
  'Type',
  'Spend Amount',
  'Family Amount Spent',
  'Total Services',
  'Claims Sharing',
]

const SpendUp = (uid, planID, plan, profile) => {
  const selfVouchers =
    plan?.familyVouchers?.find((voucher) => voucher?.memberId === uid)?.vouchers || []

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          overflow: 'scroll',
        }}
      >
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          sx={{
            width: { xs: '100%', md: '33%' },
            padding: '16px',
            marginBottom: { xs: '32px', md: '0' },
          }}
        >
          <Card sx={{ minWidth: '75%', margin: '20px auto', boxShadow: 3 }}>
            <CardContent>
              <PatientPlanCopayInfo copayInfo={plan} />
              {plan?.planData?.planDocs && <PlanDocs docs={plan?.planData?.planDocs} />}
            </CardContent>
          </Card>
        </Box>

        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          sx={{
            width: { xs: '100%', md: '33%' },
            padding: '16px',
            marginBottom: { xs: '32px', md: '0' },
          }}
        >
          {renderGauge(plan.deductible().spent, plan.deductible().total, 'Deductible')}
          {renderGauge(plan.oop().spent, plan.oop().total, 'Total OOP')}
        </Box>

        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          sx={{
            width: { xs: '100%', md: '33%' },
            padding: '16px',
            marginBottom: { xs: '32px', md: '0' },
          }}
        >
          <PatientMemberCards profile={profile} uid={uid} plan={plan} planID={planID} />

          <CertificateOfCoverage
            certificateDate={formatDateForUI(plan?.planData?.effectiveDate)}
            groupHealthPlanName={plan?.planData?.name}
            planType={plan?.planData?.planType}
            participantName={`${profile?.firstname} ${profile?.lastname}`}
            patientEmail={profile?.email}
            participantID={plan.external_id || uid}
            dependents={plan?.familyVouchers}
            participantAddress={profile?.address}
          />
        </Box>
      </Box>

      {plan.memberType === 1 ? (
        <>
          <Typography variant='h6' align='center'>
            Family Summary
          </Typography>
          <TableContainer component={Paper} sx={{ width: '100%', mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {HEADERS.map((header) => (
                    <TableCell key={header}>
                      <Typography variant='h6'>{header}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {plan.familyVouchers.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{
                        textTransform: 'capitalize',
                      }}
                    >
                      {row.name || `${row.firstname || ''} ${row.lastname || ''}`.trim()}
                    </TableCell>
                    <TableCell>{row.typeReadable}</TableCell>
                    <TableCell>
                      {formatCurrency(
                        row.vouchers.reduce((total, voucher) => total + voucher.amountPaid, 0),
                      )}
                    </TableCell>
                    <TableCell>{formatCurrency(plan.totalFamilySpend())}</TableCell>
                    <TableCell>{row.vouchers.length}</TableCell>
                    <TableCell>
                      {row.memberId !== uid && (
                        <>
                          {row.shareStatus === 'accepted' && (
                            <ViewClaimsModal
                              headerText={`View ${row.name}'s Claims`}
                              memberInfo={row}
                              plan={plan}
                            />
                          )}
                          {row.shareStatus === 'pending' && (
                            <Box display='flex' flexDirection='row'>
                              <Typography variant='body1'>Request Pending</Typography>
                            </Box>
                          )}
                          {(row.shareStatus === 'not-shared' || row.shareStatus === 'rejected') && (
                            <RequestClaimsShareModal
                              headerText={`Request Access`}
                              memberInfo={row}
                              plan={plan}
                            />
                          )}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <>
          <Typography variant='h6' align='center'>
            Summary
          </Typography>
          <TableContainer component={Paper} sx={{ width: '100%', mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {HEADERS.map((header) => (
                    <TableCell key={header}>
                      <Typography variant='h6'>{header}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      textTransform: 'capitalize',
                    }}
                  >
                    {profile?.firstname || ''} {profile?.lastname || ''}
                  </TableCell>
                  <TableCell>{plan.memberTypeReadable}</TableCell>
                  <TableCell>
                    {formatCurrency(
                      selfVouchers?.reduce((total, voucher) => total + voucher.amountPaid, 0),
                    )}
                  </TableCell>
                  <TableCell>{formatCurrency(plan.totalFamilySpend())}</TableCell>
                  <TableCell>{selfVouchers.length}</TableCell>
                  <TableCell>
                    {plan.familyVouchers.find((member) => member.memberId === uid)?.shareStatus ===
                    'accepted' ? (
                      <Typography variant='body1'>Shared</Typography>
                    ) : (
                      <ShareClaimsModal
                        headerText='Granting access to view claims'
                        memberInfo={profile}
                        plan={plan}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  )
}

export default SpendUp
